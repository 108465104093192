<template>
    <div>
        <v-card class="my-3">
            <v-card-text>
                <div class="d-flex align-center mb-3">
                    <h2 class="text-uppercase">Recommendation:</h2>
                    <v-chip
                        class="ml-2 text-upper-case font-weight-bold text-subtitle-1"
                        :color="labelColor"
                    >
                        {{ screen.recommendations.recommendation }}
                    </v-chip>
                </div>
                <template
                    v-for="(
                        { note, type, user, updated_at }, index
                    ) in screen.notes"
                >
                    <div class="d-flex align-center" :key="`label-${index}`">
                        <v-chip
                            class="subtitle-1"
                            :key="`label-${index}`"
                            label
                            :color="labelColor"
                            >{{ type }}</v-chip
                        >
                        <div class="d-flex text-subtitle-1">
                            <v-icon color="grey lighten-1" class="mr-1"
                                >mdi-account</v-icon
                            >{{ user.name }}
                            <v-icon color="grey lighten-1" class="ml-2 mr-1"
                                >mdi-calendar</v-icon
                            >{{ updated_at }}
                        </div>
                    </div>
                    <v-card
                        outlined
                        class="my-2 pa-3 text-subtitle-1 font-weight-bold"
                        :key="index"
                        v-html="note"
                    ></v-card>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import WithRecommendations from "@components/screen/mixins/WithRecommendations";
export default {
    name: "screen-recommendation",
    components: {},
    mixins: [WithRecommendations],
    props: {
        screen: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        labelColor() {
            let info = this.recommendationInfo(
                this.screen.recommendations.recommendation
            );
            return info ? info.color : "error darken-2";
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>