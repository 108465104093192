export default {
    data() {
        return {
            colorKeys: [
                {
                    term: "approved",
                    color: "success darken-2",
                    label: "Success",
                    icon: "mdi-check-circle",
                },
                {
                    term: "approval",
                    color: "success darken-2",
                    label: "Success",
                    icon: "mdi-check-circle",
                },
                {
                    term: "complete",
                    color: "success darken-2",
                    label: "Success",
                    icon: "mdi-check-circle",
                },
                {
                    term: "deposit",
                    color: "warning darken-2",
                    label: "Warning",
                    icon: "mdi-alert-circle",
                },
                {
                    term: "cancelled",
                    color: "warning darken-2",
                    label: "Warning",
                    icon: "mdi-alert-circle",
                },
                {
                    term: "denial",
                    color: "error darken-2",
                    label: "Error",
                    icon: "mdi-close-circle",
                },
                {
                    term: "denied",
                    color: "error darken-2",
                    label: "Error",
                    icon: "mdi-close-circle",
                },
                {
                    term: "review",
                    color: "error darken-2",
                    label: "Error",
                    icon: "mdi-close-circle",
                },
                {
                    term: "ia",
                    color: "error darken-2",
                    label: "Error",
                    icon: "mdi-close-circle",
                },
            ],
        };
    },
    methods: {
        recommendationInfo(source) {
            return this.colorKeys.find((key) => {
                return source.toLowerCase().search(key.term) !== -1;
            });
        },
    },
};
