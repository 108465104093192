var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "my-3" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "div",
                { staticClass: "d-flex align-center mb-3" },
                [
                  _c("h2", { staticClass: "text-uppercase" }, [
                    _vm._v("Recommendation:")
                  ]),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "ml-2 text-upper-case font-weight-bold text-subtitle-1",
                      attrs: { color: _vm.labelColor }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.screen.recommendations.recommendation) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._l(_vm.screen.notes, function(ref, index) {
                var note = ref.note
                var type = ref.type
                var user = ref.user
                var updated_at = ref.updated_at
                return [
                  _c(
                    "div",
                    {
                      key: "label-" + index,
                      staticClass: "d-flex align-center"
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          key: "label-" + index,
                          staticClass: "subtitle-1",
                          attrs: { label: "", color: _vm.labelColor }
                        },
                        [_vm._v(_vm._s(type))]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex text-subtitle-1" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "grey lighten-1" }
                            },
                            [_vm._v("mdi-account")]
                          ),
                          _vm._v(_vm._s(user.name) + " "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-2 mr-1",
                              attrs: { color: "grey lighten-1" }
                            },
                            [_vm._v("mdi-calendar")]
                          ),
                          _vm._v(_vm._s(updated_at) + " ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card", {
                    key: index,
                    staticClass: "my-2 pa-3 text-subtitle-1 font-weight-bold",
                    attrs: { outlined: "" },
                    domProps: { innerHTML: _vm._s(note) }
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }